import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	adminPanelMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import AdminPanelHeader from '../pages/_layout/_headers/AdminPanelHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{
		path: dashboardPagesMenu.myLinks.path,
		element: <DashboardHeader title='My Links' />,
	},
	{
		path: dashboardPagesMenu.credentials.path,
		element: <DashboardHeader title='Credentials' />,
	},
	{
		path: demoPagesMenu.split.path,
		element: null,
	},
	{
		path: adminPanelMenu.balances.path,
		element: <AdminPanelHeader title='Admin Panel' />,
	},
	{
		path: adminPanelMenu.workerStats.path,
		element: <AdminPanelHeader title='Admin Panel' />,
	},
	{
		path: adminPanelMenu.home.path,
		element: <AdminPanelHeader title='Admin Panel' />,
	},
	{
		path: dashboardPagesMenu.streamLinks.path,
		element: <DashboardHeader title='Stream Links' />,
	},
	{
		path: dashboardPagesMenu.linkVisits.path,
		element: <DashboardHeader title='Stream Links' />,
	},
	{
		path: dashboardPagesMenu.profile.path,
		element: <DashboardHeader title='profile' />,
	},
	{
		path: dashboardPagesMenu.pricing.path,
		element: <DashboardHeader title='pricing' />,
	},
	{
		path: dashboardPagesMenu.guides.path,
		element: <DashboardHeader title='guides' />,
	},
	{
		path: dashboardPagesMenu.tmAppManual.path,
		element: <DashboardHeader title='TM App Manual' />,
	},
	{
		path: dashboardPagesMenu.faq.path,
		element: <DashboardHeader title='faq' />,
	},
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DashboardHeader title='Home' />,
	},
	{
		path: dashboardPagesMenu.dashboardBanner.path,
		element: <DashboardHeader title='Home' />,
	},
	{
		path: demoPagesMenu.privateTabs.path,
		element: null,
	},
	{
		path: demoPagesMenu.install.path,
		element: null,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
