import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Outlet, Link, Routes, Route } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';

const useStyles: any = makeStyles((theme: any) => ({
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
	},
	content: {
		padding: theme.spacing(3),
	},
}));

const AdminPanelHeader = ({ title }: any) => {
	const classes = useStyles();

	return (
		<>
			<DashboardHeader title={title} />
			<div className={classes.root}>
				<AppBar position='static' elevation={0}>
					<Toolbar variant='dense'>
						<Typography variant='h6' className={classes.title}>
							Admin Panel
						</Typography>
						<Button disabled>
							<Link to='/admin-panel/dashboard' className={classes.link}>
								<Button color='inherit'>Dashboard</Button>
							</Link>
						</Button>
						<Link to='/admin-panel/balances' className={classes.link}>
							<Button color='inherit'>Wallet Balances</Button>
						</Link>
						<Link to='/admin-panel/device-binding-stats' className={classes.link}>
							<Button color='inherit'>Device Binding Stats</Button>
						</Link>
						<Button disabled>
							<Link to='/admin-panel/user-management' className={classes.link}>
								<Button color='inherit'>User Management</Button>
							</Link>
						</Button>
						<Button disabled>
							<Link to='/admin-panel/settings' className={classes.link}>
								<Button color='inherit'>Settings</Button>
							</Link>
						</Button>
					</Toolbar>
				</AppBar>
			</div>
		</>
	);
};

export default AdminPanelHeader;
