import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Fab, Link, TextField, Tooltip, Typography } from '@mui/material';
import { Add, Email, Payment, Refresh, Support, Telegram } from '@mui/icons-material';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Popovers from '../../../components/bootstrap/Popovers';
import Badge from '../../../components/bootstrap/Badge';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import Alert from '../../../components/bootstrap/Alert';
import Topup from '../../../components/Topup';
import { createInvoice, getApiKey, getBalance, getUser, updateUser } from '../../../Api/account';
import showNotification from '../../../components/extras/showNotification';
import LatestLinksTable from '../../../components/LatestLinksTable';
import AddonInstall from '../../../components/AddonInstall';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';

const DashboardPage = () => {
	const [topupModalOpen, setTopupModalOpen] = useState(false);
	const [addonInstallOpen, setAddonInstallOpen] = useState(false);
	const [balance, setBalance] = useState(0);
	const [apiKeyShown, setApiKeyShown] = useState(false);
	const [apiKey, setApiKey] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [actionLoading, setActionLoading] = useState(false);
	const { darkModeStatus } = useDarkMode();

	const [userInfo, setUserInfo] = useState<any>({
		isPromo: !localStorage.getItem('promotion_cancelled'),
		email: '',
		emailAdded: true,
	});
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleTopUp = () => {
		setTopupModalOpen(true);
	};

	const refreshBalance = useCallback(() => {
		return (async () => {
			const result = await getBalance();

			if (balance != result.balance) {
				setBalance(+Number(+result.balance).toFixed(2));
			}
		})();
	}, [balance]);

	async function getApiKeyValue() {
		const result = await getApiKey();

		if (result.apiKey) {
			setApiKey(result.apiKey);
		}
	}

	async function handleRefreshApiKey() {
		setActionLoading(true);
		const result = await getApiKey({ refresh: true });

		if (result.apiKey) {
			setApiKey(result.apiKey);
			showNotification(
				'Success',
				'Api key refreshed. Please make sure to let your dev/provider know.',
				'success',
			);
		}
		setActionLoading(false);
	}

	useEffect(() => {
		(async () => {
			const info = await getUser();

			setUserInfo({
				...info,
				emailAdded: !!info.email,

				isPromo: !localStorage.getItem('promotion_cancelled'),
			});
		})();
	}, []);

	useEffect(() => {
		refreshBalance();
		getApiKeyValue();
		const balanceInterval = setInterval(async () => {
			refreshBalance();
		}, 10000);

		return () => {
			clearInterval(balanceInterval);
		};
	}, [refreshBalance]);

	function copy(text: string) {
		// Create a textarea element
		const textarea = document.createElement('textarea');

		// Set the text content to the desired text
		textarea.value = text;

		// Append the textarea element to the document
		document.body.appendChild(textarea);

		// Select the text in the textarea
		textarea.select();

		try {
			// Execute the copy command
			document.execCommand('copy');
			console.log('Text copied to clipboard:', text);
			showNotification('Copied', '', 'Success');
		} catch (err) {
			// Handle any errors that may occur
			console.error('Unable to copy text to clipboard:', err);
		} finally {
			// Remove the textarea element from the document
			document.body.removeChild(textarea);
		}
	}

	return (
		<PageWrapper title='PrivateTabs' isProtected={false}>
			<div style={{ margin: 0, padding: 0, width: '100%' }}>
				<div>
					{darkModeStatus ? (
						<iframe
							style={{ width: '100%', height: 'calc(100vh - 10px)' }}
							src='https://referrals.privatetabs.com/smp-dark'
						/>
					) : (
						<iframe
							style={{ width: '100%', height: 'calc(100vh - 10px)' }}
							src='https://referrals.privatetabs.com/smp-light'
						/>
					)}
				</div>
			</div>
		</PageWrapper>
	);
};

export default DashboardPage;
