import React from 'react';
import { Box, Card, CardContent, Typography, Button, useTheme } from '@mui/material';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import ChromeIcon from '../../../components/icon/chrome-icon.svg';
import FirefoxIcon from '../../../components/icon/firefox-icon.svg';

const InstallAddon = () => {
	const theme = useTheme();
	const isDark = theme.palette.mode === 'dark';

	return (
		<PageWrapper title='Install' isProtected={false}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',
					height: '100vh',
					backgroundColor: isDark ? '#121212' : '#f9f9f9',
					color: isDark ? '#ffffff' : '#333333',
					padding: 2,
				}}>
				<Typography
					variant='h4'
					sx={{
						marginBottom: 4,
						fontWeight: 'bold',
						color: isDark ? '#ffffff' : '#333',
					}}>
					Install SecureMyPass Addon
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: 4,
						flexWrap: 'wrap',
						marginBottom: 4,
					}}>
					<a
						href='https://chromewebstore.google.com/detail/secure-my-pass/oafkfldjoadjolkfpimkddoecdmdanpa'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}>
						<Card
							sx={{
								width: 200,
								height: 200,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer',
								boxShadow: isDark ? 5 : 3,
								borderRadius: 2,
								backgroundColor: isDark ? '#1e1e1e' : '#ffffff',
							}}>
							<img
								style={{ width: 80, marginBottom: 10 }}
								src={ChromeIcon}
								alt='Chrome Icon'
							/>
							<Typography
								variant='body1'
								sx={{ fontWeight: 'bold', color: '#4285F4' }}>
								Chrome/Edge/Brave
							</Typography>
						</Card>
					</a>
					<a
						href='https://addons.mozilla.org/en-US/firefox/addon/secure-my-pass/'
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}>
						<Card
							sx={{
								width: 200,
								height: 200,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer',
								boxShadow: isDark ? 5 : 3,
								borderRadius: 2,
								backgroundColor: isDark ? '#1e1e1e' : '#ffffff',
							}}>
							<img
								style={{ width: 80, marginBottom: 10 }}
								src={FirefoxIcon}
								alt='Firefox Icon'
							/>
							<Typography
								variant='body1'
								sx={{ fontWeight: 'bold', color: '#FF7139' }}>
								Firefox
							</Typography>
						</Card>
					</a>
				</Box>
				<Box textAlign='center'>
					<Button
						href='https://securemypass.com/api/v1/download-addon?chrome=true'
						target='_blank'
						rel='noreferrer'
						sx={{
							textTransform: 'none',
							marginBottom: 1,
							color: '#6C63FF',
							fontSize: '14px',
							display: 'block',
						}}>
						Click here for unsupported Chromium browsers
					</Button>
					<Button
						href='https://securemypass.com/api/v1/download-addon?chrome=false'
						target='_blank'
						rel='noreferrer'
						sx={{
							textTransform: 'none',
							color: '#6C63FF',
							fontSize: '14px',
						}}>
						Click here for unsupported Firefox-based browsers
					</Button>
				</Box>
			</Box>
		</PageWrapper>
	);
};

export default InstallAddon;
